<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
          <b-form @submit.prevent="">
            <b-card header="Dati Identificativi" header-tag="header">
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-3">
                    <base-input
                      name="title"
                      vid="title"
                      label="Titolo"
                      v-model="form[rep].title"
                      placeholder="Inserisci un titolo"
                      :rules="{ required: true }"
                    />
                  </div>
                  <div class="col-md-3">
                    <base-select
                      name="type"
                      label="Tipo"
                      v-model="form[rep].type"
                      :options="types"
                      :taggable="false"
                      :multiple="false"
                      :rules="{ required: true }"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-textarea
                      vid="text_content"
                      name="text_content"
                      label="Testo Template"
                      v-model="form[rep].text_content"
                      placeholder="Inserisci un testo"
                      :rules="{ required: true }"
                    />
                  </div>
                </b-row>
              </b-card-text>
            </b-card>
            <div class="py-2 mb-4">
              <b-button
                @click="handleSubmit(onSubmit)"
                type="button"
                :disabled="invalid"
                variant="lisaweb"
                size="sm"
              >
                Salva
              </b-button>
              <b-button
                @click="$router.back()"
                variant="outline-lisaweb"
                size="sm"
                class="btn-reset float-right"
                ><b-icon-chevron-double-left
                  font-scale="0.9"
                ></b-icon-chevron-double-left
                >Torna indietro</b-button
              >
            </div>
          </b-form>
        </validation-observer>
      </div>
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShortcutMixin from "@/mixins/ShortcutMixin";
import LisaComunicaMixin from "@/mixins/LisaComunicaMixin";
import BaseIcon from "@/components/BaseIcon";
import BaseSelect from "@/components/form/BaseSelect";
import BaseInput from "@/components/form/BaseInput";
import BaseTextarea from "@/components/form/BaseTextarea.vue";

export default {
  mixins: [ShortcutMixin, LisaComunicaMixin],
  data() {
    return {
      repository: "sms",
      isLoading: true,
      id: this.$route.params.id,
      types: [
        { value: "AVV_SCAD", text: "Avvisi scadenza" },
        { value: "MKTG", text: "Marketing" },
        { value: "SOLL", text: "Solleciti" },
        { value: "REN_PRO", text: "Rendiconti" },
      ],
      form: {
        sms: {
          title: null,
          type: null,
          text_content: null,
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseSelect,
    BaseTextarea,
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.update()
        .then(() => {
          this.isLoading = false;
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Template Sms Modificato`,
          });
          this.shortcut("lisacomunica_template", null, "#Sms", "filterSms");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
  },
  created() {
    this.show()
      .then((response) => {
        this.initializeEditForm(response.data.content);
        this.isLoading = false;
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: `${errMsg}`,
        });
        this.isLoading = false;
        this.ok = true;
      });
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
